import { Link } from "@remix-run/react";
import { useState } from "react";

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "./ui/dropdown-menu";

const desktopItems = [
	{ label: "O PROJEKTU", to: "/#project" },
	{ label: "O NÁS", to: "/#aboutus" },
	{ label: "APLIKACE", to: "/#app" },
	{ label: "PARTNEŘI", to: "/#partners" },
	{ label: "BLOG", to: "blog" },
	{ label: "KONTAKT", to: "/#contact" },
];

export const AppHeader = () => {
	const [open, setOpen] = useState(false);

	return (
		<nav className="lg:px-32 px-6">
			<div className="container mx-auto max-w-7xl py-6 pl-0 pr-0 lg:pl-5 lg:pr-3">
				<div className="flex flex-wrap items-center justify-between gap-4 scroll-smooth sm:flex-nowrap md:gap-8">
					<Link to="/" prefetch="intent">
						<img
							width={124}
							height={31}
							src="/img/logos/echo-logo.svg"
							alt="ECHO"
						/>
					</Link>
					<div className="hidden gap-12 md:flex">
						{desktopItems.map((item) => (
							<Link to={item.to} className="font-light" key={item.label}>
								{item.label ?? item.to}
							</Link>
						))}
					</div>
					<div className="md:hidden block">
						<DropdownMenu open={open} onOpenChange={setOpen}>
							<DropdownMenuTrigger>
								<img
									src="/img/icons/menu.svg"
									alt="menu"
									width={24}
									height={20}
								/>
							</DropdownMenuTrigger>
							<DropdownMenuContent
								className="bg-white border-none w-screen text-xl leading-7 px-5"
								sideOffset={-56}
							>
								<div>
									<Link
										to="/"
										prefetch="intent"
										className="py-6 flex justify-center bg-echo-gray"
										onClick={() => setOpen(false)}
									>
										<img
											width={124}
											height={32}
											src="/img/logos/echo-logo.svg"
											alt="ECHO"
										/>
									</Link>
									<div className="flex flex-col items-center gap-6 my-8">
										<DropdownMenuItem
											className="focus:text-echo-blue"
											onClick={() => setOpen(false)}
										>
											<Link to={"#project"}>O PROJEKTU</Link>
										</DropdownMenuItem>
										<DropdownMenuItem
											className="focus:text-echo-blue"
											onClick={() => setOpen(false)}
										>
											<Link to={"#aboutus"}>O NÁS</Link>
										</DropdownMenuItem>
										<DropdownMenuItem
											className="focus:text-echo-blue"
											onClick={() => setOpen(false)}
										>
											<Link to={"#app"}>APLIKACE</Link>
										</DropdownMenuItem>
										<DropdownMenuItem
											className="focus:text-echo-blue"
											onClick={() => setOpen(false)}
										>
											<Link to={"#partners"}>PARTNEŘI</Link>
										</DropdownMenuItem>
										<DropdownMenuItem
											className="focus:text-echo-blue"
											onClick={() => setOpen(false)}
										>
											<Link to={"/blog"}>BLOG</Link>
										</DropdownMenuItem>
										<DropdownMenuItem
											className="focus:text-echo-blue"
											onClick={() => setOpen(false)}
										>
											<Link to={"#contact"}>KONTAKT</Link>
										</DropdownMenuItem>
									</div>
								</div>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				</div>
			</div>
		</nav>
	);
};
