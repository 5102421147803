import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

import { Footer } from "./components/footer";
import { AppHeader } from "./components/navigation";
import "./tailwind.css";

export function Layout({ children }: { children: React.ReactNode }) {
	return (
		<html lang="cs">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

export const ErrorBoundary = () => {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	return <div>Něco se pokazilo</div>;
};

export default function App() {
	return (
		<Layout>
			<div className="flex flex-col h-screen">
				<AppHeader />
				<main className="flex-grow">
					<Outlet />
				</main>
				<Footer />
			</div>
		</Layout>
	);
}
