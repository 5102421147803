import { Link } from "@remix-run/react";

export const Footer = () => {
	return (
		<footer className="bg-white w-full">
			<div className="container">
				<div className="flex flex-col items-center gap-12 py-12 md:hidden">
					<Link to={"/"}>
						<img
							src="/img/logos/echo-logo.svg"
							alt="echp"
							width={96}
							height={24}
						/>
					</Link>
					<div className="flex gap-6">
						<a
							href="https://apps.apple.com/cz/app/echo/id1448240689?l=cs"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/img/app-store.webp"
								alt="app-store a google play"
								width={79}
								height={24}
							/>
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.blueberry.echo"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/img/google-play.webp"
								alt="app-store a google play"
								width={79}
								height={24}
							/>
						</a>
					</div>
					<Link to={"/gdpr"} className="flex items-center text-sm">
						ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ A COOKIES
					</Link>
				</div>
				<div className="md:grid grid-cols-3 hidden px-40 py-14">
					<Link to={"/gdpr"} className="flex items-center text-sm">
						ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ A COOKIES
					</Link>
					<div className="flex justify-center">
						<Link to={"/"}>
							<img
								src="/img/logos/echo-logo.svg"
								alt="echp"
								width={96}
								height={24}
							/>
						</Link>
					</div>
					<div className="flex justify-center items-center">
						<div className="flex gap-6">
							<a
								href="https://apps.apple.com/cz/app/echo/id1448240689?l=cs"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src="/img/app-store.webp"
									alt="app-store a google play"
									width={79}
									height={24}
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.blueberry.echo"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src="/img/google-play.webp"
									alt="app-store a google play"
									width={79}
									height={24}
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
